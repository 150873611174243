import React from 'react';
import { Typography, Alert, Box } from '@mui/material'
import { isMobile } from '../../Utilities/isMobile';
import SelectedStore from '../SelectedStore';
import SelectedYearMonth from '../SelectedYearMonth';

const PageWithTitleLayout = ({ title, message, setMessage, children, style, disableStoreSelect=false, disableMonthYearSelect=false }) => {
    return (
    <div className="whole-contents">
        <div className="title-layout" style={{ ...style }}>
            {isMobile() ?
                <div className="title-bar">
                    <SelectedStore disabled={disableStoreSelect} />
                    <SelectedYearMonth disabled={disableMonthYearSelect}/>
                </div> :
                <Box sx={{ borderBottom: '1px solid lightgray', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="h6" component="div"  margin="1rem" sx={{ width: '50%'}}>
                        {title}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '50%' }}>
                        <SelectedYearMonth disabled={disableMonthYearSelect}/>
                        <SelectedStore disabled={disableStoreSelect} />
                    </Box>
                </Box>
            }
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {message?.length > 0 && (
                    <Alert
                        style={{ width: '390px', alignSelf: 'center' }}
                        onClose={() => setMessage('')}
                        severity="error"
                    >
                        {message}
                    </Alert>
                )}
            </div>
            {children}
        </div>
    </div>
)}

export default PageWithTitleLayout
