import React, { useEffect, useState, useContext } from 'react'
import { List, ListItem, ListItemText, Button, Box } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material/'
import { PATHS } from '../../constants'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import { changeSelectedStore } from '../../Utilities/authenticationService'
import { isMobile } from '../../Utilities/isMobile'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const Notifications = () => {
    const [stores, setStores] = useState([])
    const history = useHistory()
    const { userEmployments: employments } = useContext(EmploymentsContext)
    
    useEffect(() => {
        AxiosWithAuth.get('/mystores').then(res => {
            const confirmedStores = []
            res.data.forEach(e => {
                e.released_months
                    ?.sort((a, b) => moment(a).diff(moment(b)))
                    .forEach(month => {
                        const id = employments.find(employment => employment.store.seg_code === e.seg_code).id
                        confirmedStores.push({
                            emp_id: id,
                            name: e.name,
                            month: month
                        })
                    })
            })
            setStores(confirmedStores)
        })
    }, [employments])

    return (
            <div style={{ width: '100%'}}>
                <List sx={{ mx: '1rem' }}>
                    {stores.map(store => {
                        const calendarYearMonth = moment(store.month, 'YYYY-MM').format('YYYY-MM')
                        return (
                            <ListItem
                                key={store.emp_id + '' + store.month}
                                onClick={() => {
                                    changeSelectedStore(store.emp_id)
                                    isMobile()
                                        ? history.push({
                                              pathname: PATHS.checkCalendar
                                                  .replace(':employmentId', store.emp_id)
                                                  .replace(':calendarYearMonth', calendarYearMonth)
                                          })
                                        : history.push({
                                              pathname: PATHS.checkMonthlyShift
                                                  .replace(':employmentId', store.emp_id)
                                                  .replace(':calendarYearMonth', calendarYearMonth)
                                          })
                                }}
                            >   
                                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                                    <ListItemText
                                        primary={store.name}
                                        secondary={isMobile() ? moment(store.month).format('M') + '月のシフトが確認できます。' : moment(store.month).format('M') + '月分'}
                                    />
                                    {isMobile() ? <KeyboardArrowRight sx={{ ml: 'auto' }} /> :
                                        <Button variant="outlined" sx={{ ml: 'auto', height: '2rem' }}>確認する</Button>
                                    }
                                </Box>
                            </ListItem>
                        )
                    })}
                </List>
            </div>
    )
}

export default Notifications
