import { useState, useEffect } from 'react'
import './App.css'
import AdapterMoment from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import {
    PrivateRoute,
    Login,
    Signup,
    SubmitShift,
    SelectStore,
    SelectMonth,
    Calendar,
    Home,
    CheckFinalizedMonths,
    CheckFinalizedCalendar,
    CheckShiftDay,
    RecurringShifts,
    ManagerSettingsHome,
    StoreManagement,
    EditStore,
    CreateShiftStores,
    CreateShiftMonths,
    SubmittedEmployees,
    UserManagementStores,
    UserManagementList,
    UserManagementEdit,
    UserSettings,
    ForgotPassword,
    ResetPassword,
    MonthlyShiftHeader,
    EmailConfirmation,
    AdminUserManagements,
    AdminUserBulkUpload,
    AdminStoreManagements,
    AdminUserManagementList,
    AdminStoreBulkUpload,
    ResendConfirmation,
    AdminHLG,
    ViewOnlyHome,
    ShiftManagements
} from './Components'
import { PATHS } from './constants'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { EmploymentsContext } from './contexts/EmploymentsContext'
import { AxiosWithAuth } from './Utilities/authenticationService'
import chatScheduler from './Components/openai-stuff/partTimerScheduler'

function App() {
    const [userEmployments, setUserEmployments] = useState([])
    const [isManager, setIsManager] = useState(false)
    const [isManagerFor, setIsManagerFor] = useState({})
    const [canEditShift, setCanEditShift] = useState(false)
    const [canEditShiftFor, setCanEditShiftFor] = useState({})
    const [userInfo, setUserInfo] = useState({})
    const [isAdmin, setIsAdmin] = useState(false)

    const resetAppsStates = () => {
        setUserEmployments([])
        setIsManager(false)
        setIsManagerFor({})
        setCanEditShift(false)
        setCanEditShiftFor({})
        setUserInfo({})
        setIsAdmin(false)
    }

    const fetchAndSetEmployments = () => {
        AxiosWithAuth.get('/employments')
            .then(res => {
                setUserEmployments(res.data)
            })
            .catch(() => {
                setUserEmployments([])
            })
    }
    const fetchAndSetUserInfo = () => {
        AxiosWithAuth.get('/users/me')
            .then(user => {
                setUserInfo(user.data)
                setIsAdmin(user.data?.is_admin)
            })
            .catch(() => console.log('401 get /users/me'))
    }
    useEffect(() => {
        fetchAndSetEmployments()
        fetchAndSetUserInfo()
    }, [])

    useEffect(() => {
        if (userEmployments.length > 0) {
            setIsManager(userEmployments.some(employment => employment.is_manager))
            setCanEditShift(userEmployments.some(employment => employment.can_edit_shift))
            userEmployments.forEach(employment => {
                setIsManagerFor(prev => {
                    return {
                        ...prev,
                        [employment.id]: employment.is_manager
                    }
                })
                setCanEditShiftFor(prev => {
                    return {
                        ...prev,
                        [employment.id]: employment.can_edit_shift
                    }
                })
            })
        }
        if (localStorage.getItem('currentUser')) {
            setIsAdmin(JSON.parse(localStorage.getItem('currentUser')).is_admin)
        }
    }, [userEmployments, userInfo])

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Router basename={process.env.PUBLIC_URL}>
                <EmploymentsContext.Provider
                    value={{
                        userEmployments,
                        setUserEmployments,
                        isManager,
                        isAdmin,
                        userInfo,
                        isManagerFor,
                        canEditShift,
                        canEditShiftFor,
                        fetchAndSetEmployments,
                        resetAppsStates
                    }}
                >
                    <Switch>
                        <Route exact path="/">
                            <Redirect to={PATHS.home} />
                        </Route>
                        {/*TODO: add home path public route with token */}
                        <Route
                            path={PATHS.login}
                            component={props => {
                                return (
                                    <Login
                                        {...props}
                                        fetchAndSetEmployments={fetchAndSetEmployments}
                                        fetchAndSetUserInfo={fetchAndSetUserInfo}
                                    />
                                )
                            }}
                        />
                        <Route path={PATHS.forgotPassword} component={ForgotPassword} />
                        <Route path={PATHS.resetPassword} component={ResetPassword} />
                        <Route path={PATHS.confirmation} component={EmailConfirmation} />
                        <Route path={PATHS.resendConfirmation} component={ResendConfirmation} />
                        <PrivateRoute path={PATHS.signup} component={Signup} />
                        <PrivateRoute path={PATHS.home} component={Home} />
                        <PrivateRoute path={PATHS.submitShift} component={SubmitShift} />{' '}
                        {/* reverse order for routing to work, will fix with nesting later */}
                        <PrivateRoute path={PATHS.calendar} component={Calendar} />
                        <PrivateRoute path={PATHS.months} component={SelectMonth} />
                        <PrivateRoute path={PATHS.stores} component={SelectStore} />
                        <PrivateRoute path={PATHS.check} component={CheckFinalizedMonths} />
                        <PrivateRoute path={PATHS.checkCalendar} component={CheckFinalizedCalendar} />
                        <PrivateRoute path={PATHS.checkDay} component={CheckShiftDay} />
                        <PrivateRoute path={PATHS.checkMonthlyShift} component={MonthlyShiftHeader} />
                        <PrivateRoute path={PATHS.createMonthlyShift} component={MonthlyShiftHeader} />
                        <PrivateRoute path={PATHS.recurring} component={RecurringShifts} />
                        <PrivateRoute path={PATHS.editStore} component={EditStore} />
                        <PrivateRoute path={PATHS.storeManagement} component={StoreManagement} />
                        <PrivateRoute path={PATHS.userSettings} component={UserSettings} />
                        <PrivateRoute path={PATHS.userManagementEdit} component={UserManagementEdit} />
                        <PrivateRoute path={PATHS.userManagementList} component={UserManagementList} />
                        <PrivateRoute path={PATHS.userManagementStores} component={UserManagementStores} />
                        <PrivateRoute exact path={PATHS.managerSettings} component={ManagerSettingsHome} />
                        <PrivateRoute exact path={PATHS.submittedEmployees} component={SubmittedEmployees} />
                        <PrivateRoute path={PATHS.createDay} component={CheckShiftDay} />
                        <PrivateRoute path={PATHS.createCalendar} component={CheckFinalizedCalendar} />
                        <PrivateRoute exact path={PATHS.managerStores} component={CreateShiftStores} />
                        <PrivateRoute exact path={PATHS.managerMonths} component={CreateShiftMonths} />
                        <PrivateRoute exact path={PATHS.adminUserManagements} component={AdminUserManagements} />
                        <PrivateRoute path={PATHS.adminUserBulkUpload} component={AdminUserBulkUpload} />
                        <PrivateRoute path={PATHS.adminStoreBulkUpload} component={AdminStoreBulkUpload} />
                        <PrivateRoute exact path={PATHS.adminStoreManagements} component={AdminStoreManagements} />
                        <PrivateRoute exact path={PATHS.adminStoreUserList} component={AdminUserManagementList} />
                        <PrivateRoute exact path={PATHS.adminHLG} component={AdminHLG} />
                        <PrivateRoute path={'/chatScheduler'} component={chatScheduler} />
                        <PrivateRoute path={PATHS.viewOnly.checkMonthlyShiftMobile} component={CheckFinalizedCalendar} />
                        <PrivateRoute path={PATHS.viewOnly.checkMonthlyShift} component={MonthlyShiftHeader} />
                        <PrivateRoute path={PATHS.shiftManagement} component={ShiftManagements} />
                        
                        <PrivateRoute path={PATHS.viewOnly.home} component={ViewOnlyHome} />
                    </Switch>
                </EmploymentsContext.Provider>
            </Router>
        </LocalizationProvider>
    )
}

export default App
