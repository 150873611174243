import React, { useEffect, useState, useContext } from 'react'
import { Alert, Typography, Button, Collapse, Box, Card } from '@mui/material'
import { KeyboardArrowRight,  Add as AddIcon } from '@mui/icons-material/'
import { PATHS } from '../../constants'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import Notifications from './Notifications'
import ShiftSubmissions from './ShiftSubmissions'
import { isMobile } from '../../Utilities/isMobile'
import moment from 'moment'
moment.locale('ja')
moment.updateLocale('en', {
    weekdaysShort: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日']
})

const Home = ({ history }) => {
    const [submitted, setSubmitted] = useState(true)
    const [notifications, setNotifications] = useState([])
    const { setUserEmployments, isManager, isAdmin, userInfo } = useContext(EmploymentsContext)
    const selectedEmploymentId = parseInt(localStorage.selected_emp)
    const [alerts, setAlerts] = useState([])
    const now = new Date()

    const firstHalf = now.getDate() < 16
    const currentShiftMonthDate = new Date()
    currentShiftMonthDate.setMonth(now.getMonth() + (firstHalf ? 1 : 2))
    const currentShiftMonth = currentShiftMonthDate.toISOString().slice(0, 7) // YYYY-MM
    // if it's earlier than the 16th, 'current' month is next month
    // later than the 16th -> month after next

    const handleNotificationClose = n => {
        AxiosWithAuth.put(`/notifications/${n.id}`, {
            is_read: true
        })
            .then(() => {
                const newNotifications = notifications.filter(not => not.id !== n.id)
                const updatedNotification = notifications.find(not => not.id === n.id)
                updatedNotification.is_read = true
                setNotifications([...newNotifications, updatedNotification])
            })
            .catch(err => console.log(err))
    }
    const getGreeting = () => {
        const currentHour = moment().hour();
        if (currentHour < 12) {
            return 'おはようございます🌿';
        } else if (currentHour < 18) {
            return 'こんにちは🌞';
        } else {
            return 'こんばんは🌙';
        }
    };
    useEffect(() => {
        AxiosWithAuth.get('/employments').then(res => {
            let submit = true
            let notice = []
            setUserEmployments(res.data)
            res.data.forEach(e => {
                if (!(e.submitted.includes(currentShiftMonth) || e.confirmed.includes(currentShiftMonth))) {
                    // if any of the employments is missing a submitted shift for the current month
                    submit = false
                }
                if (e.notifications.length > 0) {
                    e.notifications.forEach(n => {
                        if (n.is_read === false) {
                            notice.push(n)
                        }
                    })
                }
            })
            if (!submit) setSubmitted(false)
            if (notice.length > 0) setNotifications(notice)
        })
        AxiosWithAuth.get('/alerts').then(res => {
            let combinedAlerts = []
            for (const [, value] of Object.entries(res.data)) {
                if (value.length > 0) {
                    combinedAlerts = [...combinedAlerts, ...value]
                }
            }
            setAlerts(combinedAlerts)
        })
    }, [firstHalf, currentShiftMonth])

    if (userInfo?.view_only) {
        return <Redirect to={PATHS.viewOnly.home} />
    }

    const homeComponent = (
        <PageWithTitleLayout title="ホーム">
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', my: 2, flexDirection: 'column' }}>
                    <Typography variant="h6">
                        {moment().format('YYYY年M月D日 ddd')}
                    </Typography>
                    <Typography variant="h7" sx={{ ml: 2 }}>
                        {getGreeting()}
                    </Typography>
                    <Button
                        variant='contained'
                        sx={{ m: 3, borderRadius: '20px'}}
                        onClick={() => history.push(PATHS.checkDay.replace(':employmentId', selectedEmploymentId).replace(':shiftDate', moment().format('YYYY-M-D')))}
                    >
                        今日のシフトをチェック<KeyboardArrowRight />
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', m: 2, flexDirection: isMobile() ? 'column' : 'row'}}>
                    <Card sx={{ width: isMobile() ? '90%' : '40%', minHeight: '30vh',display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, mt: 2 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            📣 お知らせ
                        </Typography>
                        <Box sx={{overflow: 'auto', maxHeight: '50vh', width: '100%'}}>
                        {notifications.length > 0 &&
                            notifications.map((n, i) => (
                                <Collapse in={!n.is_read} key={i}>
                                    <Alert
                                        key={i}
                                        severity="info"
                                        onClose={() => handleNotificationClose(n)}
                                        sx={{ display: 'flex' }}
                                    >
                                        お知らせ
                                        <br />
                                        {n.message}
                                    </Alert>
                                </Collapse>
                            ))
                        }
                        {alerts.length > 0 &&
                            alerts.map(alert => (
                                <Alert severity={alert.severity} key={alert.message}>
                                    {alert.message}
                                </Alert>
                            ))
                        }
                            <Notifications />
                        </Box>
                    </Card>
                    <Card sx={{ width: isMobile() ? '90%' : '40%', minHeight: '30vh', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, mt: 2 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            🗓️ シフト提出状況
                        </Typography>
                        <Alert severity="error" sx={{ display: !submitted && firstHalf ? 'flex' : 'none' }}>
                            お知らせ
                            <br />
                            {((currentShiftMonthDate.getMonth() + 11) % 12) + 1}月16日までに
                            {currentShiftMonthDate.getMonth() + 1}月のシフトを提出してください。
                        </Alert>
                        <Box sx={{overflow: 'auto', maxHeight: '50vh', width: '100%'}}>
                            <ShiftSubmissions currentShiftMonth={currentShiftMonth} />
                        </Box>
                    </Card>
                </Box>
            </Box>
            {(isManager || isAdmin) && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: '2rem', marginBottom: '4.5rem' }}>
                    <Button
                        onClick={e => {
                            e.preventDefault()
                            history.push(PATHS.signup)
                        }}
                        variant="contained"
                        endIcon={<AddIcon />}
                        sx={{
                            mt: 3,
                            height: '42px',
                            backgroundColor: '#E0E0E0',
                            color: 'black',
                            borderRadius: '20px'
                        }}
                    >
                        新規ユーザー登録
                    </Button>
                </Box>
            )}
        </PageWithTitleLayout>
    )

    return homeComponent
}

export default Home
