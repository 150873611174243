import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography, List, ListItem, ListItemIcon, Badge } from '@mui/material'
import { CalendarTodayOutlined, KeyboardArrowRight } from '@mui/icons-material/'
import moment from 'moment'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import SelectedStore from '../SelectedStore'
import { isMobile } from '../../Utilities/isMobile'

const SelectMonth = ({ history, location }) => {
    const { employmentId } = useParams()
    const [submittedMonths, setSubmittedMonths] = useState()
    const [closedMonths, setClosedMonths] = useState()
    const now = new Date()

    const months = [
        new Date(now.getFullYear(), now.getMonth()),
        new Date(now.getFullYear(), now.getMonth() + 1),
        new Date(now.getFullYear(), now.getMonth() + 2)
    ]

    useEffect(() => {
        AxiosWithAuth.get('/employments/' + employmentId).then(res => {
            setSubmittedMonths(res.data?.submitted)
            setClosedMonths(res.data?.store.closed_months)
        })
    }, [employmentId])

    if (!submittedMonths) return <></>

    return (
        <PageWithTitleLayout title="希望シフトを提出する">
            {isMobile() && <SelectedStore />}
            <Box className="shrinkable">
                <Typography variant="subtitle2" margin="1rem" marginTop=".5rem" marginBottom=".5rem">
                    希望シフトを提出する月を選択してください。
                </Typography>
                <List sx={{ mx: '1rem' }}>
                    {months.map(e => {
                        const year = e.getFullYear()
                        const month = e.getMonth() + 1 // js months are 0 index
                        const targetString = moment(e).format('YYYY-MM')
                        return (
                            <ListItem
                                key={month}
                                onClick={() => history.push(`${location.pathname}/${moment(e).format('YYYY-MM')}`)}
                            >
                                <ListItemIcon>
                                    <CalendarTodayOutlined />
                                </ListItemIcon>
                                {month}月分
                                {closedMonths?.includes(targetString) ? (
                                    <Badge
                                        badgeContent="締め切り済み"
                                        color="paloBlue"
                                        sx={{
                                            width: '6rem',
                                            ml: 'auto',
                                            mr: '2rem'
                                        }}
                                    />
                                ) : submittedMonths?.find(
                                      e => e == `${year}-${String(month).length > 1 ? month : '0' + month}`
                                  ) ? (
                                    <Badge
                                        badgeContent="提出済み"
                                        color="success"
                                        sx={{
                                            width: '4rem',
                                            ml: 'auto',
                                            mr: '2rem'
                                        }}
                                    />
                                ) : (
                                    <KeyboardArrowRight sx={{ ml: 'auto' }} />
                                )}
                            </ListItem>
                        )
                    })}
                </List>
            </Box>
        </PageWithTitleLayout>
    )
}

export default SelectMonth
